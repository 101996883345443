.container-products{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
}
.container-products a{
    text-decoration: none;
    color:black;
}
.container-products .card{
    margin-top: 5px;
    width: 200px;
    border:1px solid rgb(231, 230, 230);
    border-radius: 5px;
    box-shadow: 0px 0px 5px 1px rgb(243, 242, 242);
    background-color: rgb(246, 246, 246);
}
@media screen and (max-width:430px) {
    .container-products a{
        width:48%;
    }
    .container-products .card{
        width:100%;
        height:auto;
    }
    
}
.container-products .card-head img{
    display: block;
    margin: 2px auto;
    height:240px;
    width: 98%;
    border-radius: 5px;
}
.container-products .card-body{
    padding-left: 8px;
}
.card-body p{
    color:gray;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    height: 23px;
}
.card-body div{
    color: rgb(31, 151, 49);
    font-weight: 700;
    padding-bottom: 10px;
}