
.detail {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
}
.container-pdetail img {
    width: 290px;
    max-height: 490px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

@media only screen and (max-width:850px) {
    .container-pdetail img {
        max-width: 250px;
        max-height: 400px;
    }
}

.detail-content-container {
    width: 350px;
    position: absolute;
    left: 380px;
    margin-top: 10px;
}

@media only screen and (max-width:1100px) {
    .detail-content-container {
        position: initial;
    }
}
.detail-content{
    margin-left: 20px;
}
.detail-price {
    color: rgb(31, 151, 49);
    font-weight: 700;
    padding-top: 10px;
}

.detail-content-container>p {
    color: gray;
    padding-top: 10px;
}

.detail-content-container ol {
    padding-left: 15px;
}

.detail-content-container button {
    color: white;
    border: none;
    background-color: rgb(31, 151, 49);
    display: flex;
}

/* zoom */

* {
    box-sizing: border-box;
}

.img-zoom-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.img-container {
    position: relative;
    display:flex;
    justify-content: center;
}

.img-container:hover~.img-zoom-result {
    visibility: visible;
    z-index: 1;
}

#detail-img:hover~ #lens{
  visibility: visible;
}

#lens {
    top: 10px;
    left: 10px;
    visibility: hidden;
}

#detail-img {
    width: 380px;
    height: 400px;
    border-radius: 10px;
}
@media only screen and (max-width:400px){
    #detail-img {
        width: 95%;
        height: 400px;
    }
}


.img-zoom-lens {
    position: absolute;
    /* border: 1px solid #ebeaea; */
    box-shadow: 0px 0px 6px 2px #979696;
    /*set the size of the lens:*/
    width: 70px;
    height: 90px;
    border-radius: 10px;
}

.img-zoom-result {
    border: 1px solid #d4d4d4;
    /*set the size of the result div:*/
    width: 380px;
    height: 400px;
    border-radius: 10px;
    visibility: collapse;

}

@media only screen and (max-width:1100px) {
    .img-zoom-result,.img-zoom-lens  {
        display: none;
    }
}

.show {
    display: block;
}