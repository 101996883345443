.container-form{
    border:1px solid gray;
    border-radius: 2px;
    position: relative;
}
.container-form > div{
    margin: auto;
    width: 90%;
}
#form-auth{
    width:290px;
}
#form-product,#form-payment{
    width: 500px;
}

.container-form h2{
    text-align: center;
    padding:10px;
    margin: auto;
    border-bottom: 1px solid lightgray;
}
.form{
    display: flex;
    flex-direction: column;
    padding:10px;
}
.form > div{
padding-top: 5px;    
display: flex;
flex-direction: column;
}

.form input , button,select,option,textarea{
    margin-top: 10px;
    padding:12px;
    border-radius: 5px;
}
.form input,select,textarea{
    background-color: rgb(235, 234, 234);
    border: 1px lightgray solid;
    border-radius: 2px;
}
.form button{
    font-weight: bold;
    font-size: 15px;
    color:white;
    background-color: #007bff;
    border:none;
}
@media only screen and (max-width:550px){
    .container-form{
        width: 99%;
        margin: auto;
    }#form-product{
        margin: auto;
        width: 97%;
    }
}