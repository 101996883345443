.layout{
  min-width: 60%;
  display: flex;
  justify-content: space-around;
}
@media only screen and (max-width:1080px){
  .layout{
    flex-direction: column;
  }
}
.layout .content{
  width: 850px;
}
@media only screen and (max-width:850px){
    .layout .content{
       width: 99%;
       margin: auto;
    }
}